body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

.popup-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    background-color: #fff;
    max-width: 1000px;
    margin: 40px auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.left-section {
    flex: 1;
    padding-right: 40px;
}

.left-section h1 {
    font-size: 54px !important;
    margin-bottom: 20px;
    /* line-height: 1.4; */
}

.left-section p {
    font-size: 14px;
    margin-bottom: 10px;
}

.left-section a {
    color: #0066cc;
    text-decoration: none;
}

.left-section a:hover {
    text-decoration: underline;
}

.right-section {
    flex: 1;
    padding-left: 40px;
}

.consultation-form {
    display: flex;
    flex-direction: column;
}

.consultation-form h2 {
    font-size: 20px;
    margin-bottom: 20px;
}

.consultation-form input,
.consultation-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border: 2px solid #ccc;
    border-radius: 4px;
    border-radius: 10px;
    border-color: #9FC4B7;
}

input[type="radio"] {
    width: 18px;
    height: 18px;
}

input[type="checkbox"] {
    width: 18px;
    height: 18px;
}

.consultation-form textarea {
    resize: vertical;
}

.radio-group {
    margin-bottom: 15px;
}

.radio-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.radio-group div {
    margin-bottom: 5px;
}

.radio-group input {
    margin-right: 5px;
}

.checkbox-group {
    margin-bottom: 15px;
}

.checkbox-group input {
    margin-right: 5px;
}

button[type="submit"] {
    padding: 10px 15px;
    background-color: #98C647;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

button[type="submit"]:hover {
    background-color: #98C647;
}

.customborder {
    border-radius: 8px !important;
}

.customfont {
    font-family: none !important;
}

.customColor {
    color: #98C647 !important;
    font-size: 30px !important;
}

.placeholderColor {
    color: #999999 !important;
}
/* .font_style{
    font-family:;
} */





/* MOBILE RESPONSIVE */
/* Adjustments for mobile responsiveness */
@media (max-width: 768px) {
    .left-section {
        text-align: center;
    }

    .consultation-form {
        width: 100%;
    }

    .customborder {
        width: 100%;
    }

    .left-section, .right-section {
        padding: 0 15px;
    }
}

/* Additional styling */
.consultation-form input,
.consultation-form textarea {
    width: 100%;
    margin-bottom: 10px;
}

.radio-group {
    margin-bottom: 20px;
}

.checkbox-group {
    margin-bottom: 20px;
}

.customborder {
    border: 2px solid #000;
    background-color: #fff;
    padding: 10px 20px;
    cursor: pointer;
}

.customfont {
    font-family: 'Arial', sans-serif;
}

/* Loader.css */

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #98C647;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.colorchng {
    color: #98C647 !important;
}

.modal-header .btn-close {
    margin-top: 5px !important;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .left-section h1 {
        font-size: 14px !important;
        margin-bottom: 10px;
        text-align: justify;
    }

    .left-section p {
        font-size: 9px !important;
        text-align: justify;


    }

    .modal-header .btn-close {
        font-size: 10px !important;
    }

    .customColor {

        font-size: 20px !important;
    }
}
