.text-color {
    color: #98C647;
}

.fs-size {
    font-size: 82px !important;
}

.banner {
    width: 983px;
    transform: translate(-330px, 15px);
}

.add_transform {
    transform: translate(0px, -25px);
}

.health {
    border: 4px solid #98C647;
    border-radius: 50% !important
}

.btn-color {
    background-color: #98C647 !important;

}

.bg-img {
    background-image: url(../../assets/_ARC\ Website\ Landing\ Page\ \(10\).png);
    background-position: center;
    /* object-fit: cover; */
    background-size: 47rem 28rem;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* position: static; */
    transform: translate(0px, 10px);
}

.eight {
    font-size: 102px;
    font-weight: 800;
}

.plus {

    font-weight: bolder(100, 100, 300);
}

.bg-color {
    background-color: #98C647;
}

.img-icom {
    width: 50px;
    height: 50px;
}

.img-icom2 {
    width: 60px;
    height: 40px;
}


.section_box {
    background: linear-gradient(45deg, #98C647, #74a3ab);
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: none !important;
    cursor: pointer;
}

.add_small_custom_height {
    height: 4rem !important;
}

.add_custom_height {
    height: 4.6rem;
    font-size: 14px;
}

.location {
    width: 120px;
}

.border_right {
    border-right: 2px solid black;
}

.btn_width {
    width: 275px !important;
    border: 2px solid white !important;
    color: white !important;

}
.btn_width22 {
    width: 275px !important;
    border: 2px solid white !important;
    color: white !important;

}



.customheight {
    height: 2rem;
}

.tick {
    width: 80px;
}

.bordercolor {
    border-color: #9FC4B7 !important;
    border: 2px solid #ccc;
}

.facebook {
    width: 40px;
}

.instagram {
    width: 40px;
}

.tiwtter {
    width: 40px;
}

.customFont {
    font-family: none !important;
    font-size: 52px;

}

.add_img_weight {
    width: 7rem;
}

.add_font_small {
    font-size: 12px;
    line-height: 16px;
}

.customcolor {
    color: #98C647 !important;
}

.add_two_img {
    width: 6rem;
}

.customradius {
    border-radius: 21px !important;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

@media screen and (max-width: 769px) {
.add_small_custom_height {
    height: 6rem !important;
}
}
.alignText {
    text-align: justify !important;
    
}

.btnColor {
    background-color: #6E8F84;
    margin-right: 141px;
}

.text-justify {
    font-size: 14px;
    line-height: 19px;
    text-align: justify;
}

.para_text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    text-align: justify;
}

.img_two {
    width: 25rem;
    height: 23rem;
}

.img_one {
    width: 25rem;
    height: 23rem;
}

.imgCLass {
    height: 80px !important;
}

.add_font_para_text {
    font-size: 16px;
}

.add_font_heading_data {
    font-size: 20px;
    line-height: 24px;
}

.add_head_data {
    font-size: 40px !important;
}

.add_itlic_data {
    font-size: 16px;
    line-height: 20.5px;
}

.add_itlic_datas {
    font-size: 18px;

}

.font_size {
    font-size: 15px !important;
}

.add_font_second {
    font-size: 20px;
}

.customclor {
    background-color: #98C647 !important;
    color: white !important;
}

.tc {
    font-size: 10px;

}

a {
    color: black !important;
    text-decoration: none !important;
}

.row_eight {
    font-size: 45px;
}
.flex_rul{
    display: flex!important;
}
.footer_text{
    font-size: 18px;
}
.copy{
    color:#cccccc!important;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
    .copy{
        font-size: 14px;
        line-height: 16px;
    }
    .flex_rul{
        display: block!important;
    }
    .footer_text{
        font-size: 12px;
    }
    .img_two {
        width: 21rem;
        height: 21rem;
        margin-bottom: 10px !important;
    }
    .imgCLass {
        height: 60px !important;
    }

    .moblie_center {
        text-align: center !important;
    }

    .size-set {
        font-size: 10px !important;

    }

    .font_size {
        font-size: 9px !important;
    }

    .img-icom2 {
        width: 50px;
        height: 26px;
    }

    .img_one {
        width: 21rem;
        height: 21rem;
        margin-bottom: 10px !important;
    }

    .text-color {
        font-size: 12px;
    }

    .fs-size {
        font-size: 35px !important;
    }

    .banner {
        width: 100%;
        transform: none !important;

    }

    .customFont {
        font-family: none !important;
        font-size: 40px;
        justify-content: center !important;
        text-align: center;
    }

    .add_img_weight {
        width: 5rem;
    }

    .row_eight {
        justify-content: center;
    }

    i.fa.fa-plus {
        font-size: 12px;
    }

    .add_transform {
        transform: none !important;
    }

    .para_two_div p {
        font-size: 12px;
        line-height: 16px;
    }

    .text_size_set {
        font-size: 9px;
    }

    .add_font_para_text {
        font-size: 11px;
    }

    .add_font_heading_data {
        font-size: 15px;
        line-height: 18px;
    }

    .add_head_data {
        font-size: 22px !important;
    }

    .add_itlic_data {
        font-size: 11px !important;
    }

    .add_custom_height {
        height: 2.7rem;
        font-size: 12px;
    }

    .img-icom {
        width: 60px;
        height: 62px;
    }

    .add_font_second {
        font-size: 12px;
    }

    .add_two_img {
        width: 3.5rem;
    }

    .bg-img {

        background-size: 25rem 13rem;

    }

    .border_right {
        border-right: none
    }

    .customheight {
        height: 1.5rem;
    }

    .add_itlic_datas {
        font-size: 11px;
    }

    .btn_width22 {
        width: 152px !important;
        border: 2px solid white;
        text-align: center !important;
        font-size: 7px !important;
    }
    .btn_width {
        width: 203px !important;
        border: 2px solid white;
        text-align: center !important;
        font-size: 10px !important;
    }

    .tick {
        width: 50px;
    }

    .customclor {
        font-size: 8px !important;
    }

    .customclor1 {
        font-size: 10px !important;
    }


    .setheight {
        margin-top: -13px !important;

    }
}

@media only screen and (min-width: 1100px) and (max-width: 1380px) {
    .fs-size {
        font-size: 69px !important;

    }

    .banner {
        width: 904px !important;
    }

    .font_size {
        font-size: 13px !important;
    }
}
