.logo {
    width: 15%;
}

.btn-color {
    background-color: #98C647 !important;

}

.customColor {
    color: #98C647 !important;
    /* font-weight: 700 !important; */
    font-size: 78px !important;
    cursor: pointer;
}


@media only screen and (min-width: 320px) and (max-width: 768px) {
    .logo {
        width: 29% !important;

    }

    .btn-color {
        font-size: 12px !important;
        line-height: 16px;
    }

    .customColor {
        color: #98C647 !important;
        /* font-weight: 700 !important; */
        font-size: 20px !important;
        cursor: pointer;
    }
}
